import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import MailchimpSignup from 'src/components/MailchimpSignup'
import MaterialIcon from 'src/components/MaterialIcon'
import { FaSpotify } from 'react-icons/fa'
import { SiDeezer, SiApplemusic, SiTidal } from 'react-icons/si'
import { mq, animations, colors } from 'src/styles'
import Grid from 'src/components/Grid'
import ContentfulRichText from 'src/components/ContentfulRichText'
import moment from 'moment'

import img from './LowAlbumCover-seamless.jpg'
import hotelLogo from './hotelLogo.png'
import chetFakerName from './chet-faker-name.png'

const Splash = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    background: #EBE3E9;
    font-size: 14px;
    text-align: center;
    z-index: 4;
    overflow: auto;
    background-size: 130%;
    background-position: left top;
    
    ${mq.largeAndBelow} {
        background-image: none !important;
    }
`
const ImageWrapper = styled.div`
    background-size: cover;
    background-position: center top;
    display: none;
    position: relative;

    ${mq.largerAndUp} {
        display: block;
        height: 100%;
    }

    img {
        display: none;

        position: absolute;
        height: 16px;

        ${mq.largerAndUp} {
            top: calc(55vw + 1%);
            top: calc(51.5vw + 1%);
            left: calc(39% + 5vw);
        }
    }
`

const ImageWrapperMobile = styled.div`
    margin-bottom: 56px;

    img {
        width: 100%;
    }

    figure {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
            height: 16px;
            width: auto;
        }
    }

    ${mq.largerAndUp} {
        display: none;
    }
`
const Title = styled.div`
    margin-bottom: 56px;
    font-weight: bold;   
    
    ${mq.largeAndBelow} {
        margin-bottom: 32px;
    }

    p {
        font-weight: bold;
        margin: 0;
        font-size: 12px;
    }

    img {
        margin-bottom: 16px;
        width: 225px;
    }
`

const Wrapper = styled(Grid)`
    text-align: center;
    position: relative;

    ${mq.largeAndUp} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
    ${mq.largerAndUp} {
        padding-bottom: 0;
    }

    ${mq.largeAndBelow} {
        position: relative;
        top: calc(-90vw);
    }

    figure {
        img {
            height: 16px;
            width: auto;
        }

        ${mq.largerAndUp} {
            // display: none;
        }
    }
    ${ mq.mediumAndUp } {
        padding-bottom: 56px;
        margin-bottom: 100px;
    }
`

const FooterText = styled.div`
    text-align: center;
    color: rgba(0,0,0,0.75);
    padding-top: 56px;

    a:hover, a:focus {
        text-decoration: underline;
    }

    ${mq.largerAndUp} {
        text-align: left;
        position: absolute;
        right: 24px;
        bottom: 0;
    }
`

const ButtonWrapper = styled(Button)`
    border: currentColor;
    width: 260px;
    height: 60px;
    border-radius: 30px ;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: space-around;
    color: black;
    font-weight: bold;
    background: transparent;
    transition: 0.25s;

    &:hover, &:focus {
        border-color: currentColor;
        background-color: transparent;
    }
`

const BuyButton = styled(ButtonWrapper)`
    border: 2px solid black;
    position: relative;

    ${mq.largeAndBelow} {
        margin-bottom: 32px;
    }

    svg * {
        fill: black;        
    }

    &:hover {
        border-color: black;
        svg * {
            fill: white;        
        }
    }   

    &:hover div, &:focus div {
        display: block;
        color: black;
    }
    &:hover article, &:focus article {
        display: none;
    }
    &:hover span, &:focus span {
        display: none;
    }
    &:hover aside, &:focus aside {
        display: block;
    }
    ${mq.mediumAndBelow} {
        margin-bottom: 12px;
    }
`

const CountrySelector = styled.div`
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 260px;

    strong {
        font-size: 28px;
        letter-spacing: 2%;

        a {
            margin: 0 5px;
        }
    }
`

const HelperText = styled.aside`
    display: none;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    width: 260px;
    font-size: 12px;
`

const SpotifyButton = styled(ButtonWrapper)`
    border: 2px solid #36b759;
    svg * {
        fill: #36b759;        
    }
    &:hover, &:focus {
        background: #36b759;
        border-color: #36b759;
        svg * {
            fill: white;        
        }
    }
`

const DeezerButton = styled(ButtonWrapper)`
    border: 2px solid #ED5669;
    svg * {
        fill: #ED5669;        
    }
    &:hover, &:focus {
        background: #ED5669;
        border-color: #ED5669;
        svg * {
            fill: white;        
        }   
    }
`

const AppleButton = styled(ButtonWrapper)`
    border: 2px solid #f95770;
    svg * {
        fill: #f95770;        
    }
    &:hover, &:focus {
        background: #f95770;
        border-color: #f95770;
        svg * {
            fill: white;        
        }
    }
`

const TidalButton = styled(ButtonWrapper)`
    border: 2px solid black;
    svg * {
        fill: black;        
    }
    &:hover, &:focus {
        background: black;
        border-color: black;
        svg * {
            fill: white;        
        }
    }
`

const InsideText = styled.article`
    margin-left: 20px;
    font-size: 12px;
    text-align: left;
`

const TermsLink = styled.article`
    font-size: 10px;
    text-align: left;
    margin-bottom: 20px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 90%;
        left: 1px;
        width: calc(100% - 2px);
        height: 1px;
        background: black;
        opacity: 0;
        transition: opacity ${ animations.mediumSpeed} ease-in-out;
    }
    &:hover {
        cursor: pointer;
        &:after {
            opacity: 1;
        }
    }
    ${ mq.mediumAndBelow } {
        text-align: center;
    }
`

const StyledGrid = styled(Grid)`
    // height: 100%;
    ${mq.largeAndBelow} {
        grid-direction: ltr;
    }

    ${ mq.mediumAndUp } {
        padding-top: 100px;
    }
`

const EmailWrapper = styled.div`
    form {
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid black;
        width: 260px;
        padding-bottom: 12px;
        // text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    
    input[type="email"] {
        color: black;
	    padding: 0;
	    border-radius: 0;
	    background: transparent;
	    outline-offset: 0;
	    max-width: 100%;
        border: 0;
        font-weight: bold;
        flex-grow: 2;
    }
`

const ModalWrapper = styled.div`
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity ${ animations.mediumSpeed } ease-in-out .1s;
    ${ ({ modalOpen }) => modalOpen && `
        z-index: 4;
        opacity: 1;
  ` }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${ colors.lightGrey };
    opacity: .5;
    z-index: 1;
`

const CloseModal = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    span {
        &:hover {
            cursor: pointer;
        }
    }
    ${mq.mediumAndBelow} {
        top: 15px;
        right: 20px;
    }
`

const ModalContent = styled.div`
    position: absolute;
    top: 50px;
    bottom: 50px;
    background: ${ colors.bgColor };
    padding: 50px 100px;
    z-index: 2;

    // height: 100%;
    overflow-y: scroll;
    ${mq.mediumAndBelow} {
        padding: 50px 25px;
    }
    p {
        max-width: 650px;
        font-size: 12px;
    }

`

const TourDatesWrapper = styled.div`
    margin-top: 65px;    
`

const TourDatesTitle = styled.h6`
    text-transform: uppercase;
    margin-bottom: 36px;
`

const TourDateRow = styled(Grid)``

const DateWrap = styled.div``

const TourDate = styled.p`
    margin-top: 0;
    ${ mq.mediumAndBelow } {
        margin-top: 3px;
        font-size: 12px;
    }
`

const InfoWrap = styled.div`
    text-align: left;
`

const Venue = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 3px;
`

const Location = styled.p`
    font-size: 12px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 25px;
`

const TicketWrap = styled.div`
    a {
        position: relative;
        text-transform: uppercase;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 110%;
            left: 1px;
            width: calc(100% - 2px);
            height: 1px;
            background: black;
            transition: opacity ${ animations.mediumSpeed} ease-in-out;
        }
        ${ mq.mediumAndBelow } {
            margin-top: 3px;
            font-size: 12px;
        }
    }
`

const TicketLabel = styled.p`
    margin: 0;
    font-size: 14px;
    opacity: 0.8;
    &.red {
        color: red;
    }
    ${ mq.mediumAndBelow } {
        margin-top: 3px;
        font-size: 11px;
    }
`

const SplashPage = ({
    changeSplashPage,
    albumReleaseDate,
    bgDesktop,
    bgMobile,
    spotifyLink,
    spotifyLinkText,
    appleMusicLink,
    appleMusicLinkText,
    tidalLink,
    tidalLinkText,
    deezerLink,
    deezerLinkText,
    preOrderLinkText,
    preOrderHoverText,
    preOrderUsLink,
    preOrderUkLink,
    preOrderAustraliaLink,
    preOrderEuLink,
    preOrderGlobalLink,
    footerText,
    footerLink,
    splashPagePreOrderTc,
    splashPageTourDates
}) => {

    const [modalOpen, toggleModal] = useState(false)

    function openModal() {
      toggleModal(true);
    }

    function closeModal() {
      toggleModal(false);
    }

    if (bgDesktop) {
        var bgDesktopAddress = 'https:' + bgDesktop.fields.file.url
    }
    if (bgMobile) {
        var bgMobileAddress = 'https:' + bgMobile.fields.file.url
    }
    return (
        <Fragment>
        <Splash style={{ 
            backgroundImage: `url(${bgDesktopAddress})` 
        }}>
            <StyledGrid
                small="[1]"
                large="[7] [0] [5]"
                vAlign='center'
                gridDirection='ltr'
                >
                <ImageWrapper>
                    <img src={chetFakerName} alt=""/>
                </ImageWrapper>
                <ImageWrapperMobile>
                    <img src={bgMobileAddress} />
                </ImageWrapperMobile>
                <Wrapper
                    small="1 [12] 1"
                    large="[1]"
                >
                    <figure>
                        <img src={chetFakerName} alt=""/>
                    </figure>
                    <Title>
                        <img src={hotelLogo} />
                        <p>
                            {albumReleaseDate}
                        </p>
                    </Title>
                    <BuyButton
                        icon={<MaterialIcon size="25px">album</MaterialIcon>}>
                        <InsideText>{preOrderLinkText}</InsideText>
                        <CountrySelector>
                            <strong>
                                <a href={preOrderUsLink}>🇺🇸</a>
                                {/*<a href={preOrderUkLink}>🇬🇧</a>*/}
                                <a href={preOrderAustraliaLink}>🇦🇺</a>
                                <a href={preOrderEuLink}>🇪🇺</a>
                                {/* <a href={preOrderGlobalLink}>🌏</a> */}
                            </strong>
                        </CountrySelector>
                        <HelperText>
                            {preOrderHoverText}
                        </HelperText>
                    </BuyButton>
                    <TermsLink onClick={() => toggleModal(true)}>Pre-Order Contest Terms and Conditions</TermsLink>

                    <SpotifyButton
                        to={spotifyLink}
                        target="_blank"
                        external={true}
                        icon={<FaSpotify size="25" />}>
                        <InsideText>{spotifyLinkText}</InsideText>
                    </SpotifyButton>
                    <AppleButton
                        to={appleMusicLink}
                        target="_blank"
                        external={true}
                        icon={<SiApplemusic size="25" />}>
                            <InsideText>{appleMusicLinkText}</InsideText>
                    </AppleButton>
                    {/* <TidalButton
                        to={tidalLink}
                        target="_blank"
                        external={true}
                        icon={<SiTidal size="25" />}>
                            <InsideText>{tidalLinkText}</InsideText>
                    </TidalButton> */}
                    <DeezerButton
                        to={deezerLink}
                        target="_blank"
                        external={true}
                        icon={<SiDeezer size="25" />}>
                            <InsideText>{deezerLinkText}</InsideText>
                    </DeezerButton>

                    { splashPageTourDates ? (

                        <TourDatesWrapper>
                            <TourDatesTitle>Tour Dates</TourDatesTitle>
                            {splashPageTourDates.map((tourDate, index) => {
                                if ( tourDate.fields ) {
                                    return (
                                        <TourDateRow
                                            small="1 [3] 1 [7] 1 [3] 1"
                                            large="1 [3] 1 [6] [4] 1"
                                            vAlign='top'
                                            rowGap={['50px', '50px', '50px']}
                                        >   
                                            <DateWrap>
                                                <TourDate>{moment(tourDate.fields.eventDate).format('MM.DD.YYYY')}</TourDate>
                                            </DateWrap>

                                            <InfoWrap>
                                                <Venue>{tourDate.fields.venue}</Venue>
                                                <Location>{tourDate.fields.city}</Location>
                                            </InfoWrap>

                                            <TicketWrap>
                                                {tourDate.fields.ticketLinkUrl ? (
                                                    <a href={tourDate.fields.ticketLinkUrl} target="_blank">
                                                        {tourDate.fields.ticketLabel}
                                                    </a>
                                                ) : (

                                                    <TicketLabel className={tourDate.fields.ticketLabel.includes('SOLD') ? 'red': ''}>{tourDate.fields.ticketLabel}</TicketLabel>
                                                )}
                                            </TicketWrap>
                                        </TourDateRow>
                                    )
                                }
                            })}
                        </TourDatesWrapper>
                    ) : false}

                    <EmailWrapper>
                        <MailchimpSignup placeholder="Add your email for updates" />
                    </EmailWrapper>

                    <FooterText>
                        <a href={footerLink}>{footerText}</a>
                    </FooterText>

                </Wrapper>
                
            </StyledGrid>
        </Splash>
        <ModalWrapper modalOpen={modalOpen}>
            <Overlay onClick={() => toggleModal(false)}/>
            <ModalContent>
                <CloseModal onClick={() => toggleModal(false)}><MaterialIcon>close</MaterialIcon></CloseModal>
                <ContentfulRichText richText={splashPagePreOrderTc} />
            </ModalContent>
        </ModalWrapper>
        </Fragment>
    )
}

export default SplashPage