/* eslint-disable no-console */
import React from 'react';
import * as contentful from 'contentful'

export const CONTENT_FILTERS = {
  photos: ['contentItemImage', 'contentItemInstagramEmbed'],
  videos: ['contentItemVideo'],
  audio: ['contentItemMusic'],
  store: ['contentItemProduct']
}

const initialState = {
  cEntries: [],
  cSiteSettings: {},
}

const ContentfulContext = React.createContext(initialState);

class ContentfulState extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.contentfulClient = null;
  }

  componentDidMount() {
    this.contentfulClient = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
    })
    this.fetchAllEntries()
  }

  fetchAllEntries = () => {
    this.contentfulClient.getEntries()
      .then((response) => {
        const cSiteSettings = response && response.items && response.items
          .filter(item => item.sys.contentType.sys.id.includes('siteSettings') && item.fields.id === 'chetfaker')[0].fields
        const cEntries = response && response.items && response.items
          .filter(item => !item.sys.contentType.sys.id.includes('siteSettings'))
        this.setState({ cEntries, cSiteSettings })

      })
      // eslint-disable-next-line no-console
      .catch(e => console.log(e))
  }


  render() {
    const { children } = this.props;
    return (
      <ContentfulContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </ContentfulContext.Provider>
    )
  }
}

export default ContentfulState;

export const withContentfulContext = (Component) => (props) => (
  <ContentfulContext.Consumer>
    {(context) => (
      <Component
        {...props}
        contentfulContext={context}
      />
    )}
  </ContentfulContext.Consumer>
)