import React, { Component } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'

import * as util from 'src/styles/util'
import { colors, typography } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'

import Link from 'src/components/Link'

const buttonSizes = {
  tiny: '36px',
  small: '40px',
  medium: '50px',
  large: '66px',
}

const buttonSettings = {
  radius: '0px',
  border: '2px solid',
  transitionSpeed: '0s'
}

const getState = (loading, error, success, disabled) => {
  let buttonState = ''
  if (error) {
    buttonState = 'error'
  } else if (loading) {
    buttonState = 'loading'
  } else if (success) {
    buttonState = 'success'
  } else if (disabled) {
    buttonState = 'disabled'
  }

  return buttonState
}

const setButtonTheme = theme => `
	${ theme === 'default' || !theme ? `
		color: ${ colors.themes.default.color};
		background: ${ colors.themes.default.background};
	` : `
		color: ${ colors.themes[theme].color};
		background: ${ colors.themes[theme].background};
	` }
	&:hover {
		color: ${ colors.themes[theme].hoverColor};
		background: ${ colors.themes[theme].hoverBackground};
		${ colors.themes[theme].borderHoverColor ? `
			border-color: ${ colors.themes[theme].borderHoverColor};
		` : `
			border-color: ${ colors.themes[theme].hoverBackground};
		` }
	}
	${ colors.themes[theme].borderColor ? `
		border-color: ${ colors.themes[theme].borderColor};
	` : `
		border-color: ${ colors.themes[theme].background};
	` }
`

const disabledButtonStyles = ({ theme }) => `
  background: ${colors.lightGrey};
  border-color: ${colors.lightGrey};
  color: ${ rgba(colors.darkGrey, .5)};
  cursor: default;
  &:hover {
    background: ${colors.lightGrey};
    border-color: ${colors.lightGrey};
    color: ${ rgba(colors.darkGrey, .5)};
		cursor: default;
  }
`

const buttonStyles = (state, shape, size, theme, className) => (`
	appearance: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
	outline: none;
	display: inline-block;
	vertical-align: middle;
	border: ${ buttonSettings.border};
	height: ${ buttonSizes.medium};
	padding-top: 0;
	padding-bottom: 2px; // offset text if necessary
	padding-left: calc(${ buttonSizes.medium} * .6);
	padding-right: calc(${ buttonSizes.medium} * .6);
	cursor: pointer;
	text-transform: none;
	letter-spacing: 0;
	border-radius: ${ buttonSettings.radius};
	${ util.responsiveStyles('font-size', 20, 16, 15, 13)}
	text-align: center;
	box-shadow: none;
	${ typography.buttonStyle}
	${ util.fontSmoothing}
	transition: background ${ buttonSettings.transitionSpeed} ease-in-out,
							color ${ buttonSettings.transitionSpeed} ease-in-out,
							border ${ buttonSettings.transitionSpeed} ease-in-out,
							box-shadow ${ buttonSettings.transitionSpeed} ease-in-out,
							transform ${ buttonSettings.transitionSpeed} ease-in-out,
							opacity ${ buttonSettings.transitionSpeed} ease-in-out;
	// Button States
	${ state === 'loading' ? `cursor: wait;` : ``}
	${ state === 'error' || state === 'success' ? `cursor: default;` : ``}

	${ size ? `
		padding-left: calc(${ buttonSizes[size]} * .6);
		padding-right: calc(${ buttonSizes[size]} * .6);
		height: ${ buttonSizes[size]};
		min-width: calc(${ buttonSizes[size]} * 2);
	` : `
		min-width: calc(${ buttonSizes.medium} * 2);
	` }

	${ setButtonTheme(theme)}
	${ state === 'disabled' ? `${disabledButtonStyles({ theme })}` : ``}

	${ shape ? `
		${ shape.includes('circle') || shape.includes('square') ? `
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			${ size ? `
				width: ${ buttonSizes[size]};
				min-width: ${ buttonSizes[size]};
			` : `
				width: ${ buttonSizes.medium};
				min-width: ${ buttonSizes.medium};
			` }
		` : ``}
	` : ``}

	${ shape && shape.includes('circle') ? `border-radius: 50%;` : ``}

`)

const ButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	svg {
		* {
			fill: currentcolor;
		}
	}
`

const StyledButtonLink = styled(Link)`
	${ ({ loading, error, success, disabled, shape, size, theme }) => buttonStyles(getState(loading, error, success, disabled), shape, size, theme)}
`

const StyledButtonElement = styled.button`
	${ ({ loading, error, success, disabled, shape, size, theme }) => buttonStyles(getState(loading, error, success, disabled), shape, size, theme)}
`

class Button extends Component {
  renderIcon = icon => {
    let renderedIcon = false
    if (typeof icon === 'string') {
      renderedIcon = <MaterialIcon size={this.props.size === 'tiny' && '18px'}>{icon}</MaterialIcon>
    } else {
      renderedIcon = icon
    }
    return renderedIcon
  }

  renderButtonContent = () => {
    const { loading, error, success, children, icon, iconPosition } = this.props
    if (loading) {
      return (
        <ButtonContent>
          ...
        </ButtonContent>
      )
    } if (error) {
      return (
        <ButtonContent>
          big ole error
        </ButtonContent>
      )
    } if (success) {
      return (
        <ButtonContent>
          yes!
        </ButtonContent>
      )
    }
    return (
      <ButtonContent>
        {icon && iconPosition !== 'right' ? this.renderIcon(icon) : false}
        {children}
        {icon && iconPosition === 'right' ? this.renderIcon(icon) : false}
      </ButtonContent>
    )

  }

  render() {
    const {
      to,
      external,
      target,
      icon,
      iconPosition,
      loading,
      error,
      success,
      disabled,
      onClick,
      setTheme,
      className,
      shape,
      size,
      htmlType,
      style,
    } = this.props

    if (to) {
      return (
        <StyledButtonLink
          style={style}
          className={className}
          to={(!disabled && to)}
          target={target}
          external={external}
          icon={icon}
          iconPosition={iconPosition}
          loading={loading}
          error={error}
          success={success}
          disabled={disabled}
          onClick={(typeof onClick === 'function' && !disabled) ? onClick : null}
          theme={setTheme}
          shape={shape}
          size={size}
          type={htmlType}
        >
          {this.renderButtonContent()}
        </StyledButtonLink>
      )
    }
    return (
      <StyledButtonElement
        style={style}
        className={className}
        icon={icon}
        iconPosition={iconPosition}
        loading={loading}
        error={error}
        success={success}
        disabled={disabled}
        onClick={typeof onClick === 'function' ? onClick : null}
        theme={setTheme}
        shape={shape}
        size={size}
        type={htmlType}
      >
        {this.renderButtonContent()}
      </StyledButtonElement>
    )

  }
}

Button.defaultProps = {
  setTheme: 'default',
  size: 'medium'
}

export default Button
