/* eslint-disable import/prefer-default-export */
import * as mq from './mediaQueries'

export const shopify = `
	${mq.mediumAndBelow} {
		.shopify-buy-frame.shopify-buy-frame--product {
			max-width: 400px !important;
		}
	}
`
