// /* eslint-disable max-classes-per-file */
import React, { Component, useState } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import MaterialIcon from 'src/components/MaterialIcon'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { withDrawerContext } from 'src/state/DrawerState';
import { isValidEmail } from 'src/util/validation'

const McUrl = process.env.REACT_APP_MAILCHIMP_ENDPOINT

const FormWrapper = styled.form`
	padding-bottom: 0 !important;		

	> div {
		display: flex;
		flex-direction: row;
		width: 100%;
		// padding-bottom: 0 !important;		
	}
	
	input[type="email"] {
		font-size: 12px;
		margin-right: 8px;

		&:hover, &:focus {
			background: transparent;
		}
	}

	button {
		background: transparent;
		color: black;
		border: 0;
		padding: 0 !important;
		min-width: 24px;

		&:hover, &:focus {
			background: transparent;
		}
	}
`

const EmailWrapper = styled.div`
	display: block;
`
const StatusMessage = styled.div`
	display: block;
	margin-top: 16px;
`

const initialState = {
	email: '',
	error: '',
	nickChecked: false,
	chetChecked: false,
}

class CustomForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = initialState;
		this.timeout = null;
	}

	componentDidUpdate() {
		const {
			status,
			// drawerContext: {
			// 	toggleDrawer,
			// 	isOpen
			// }
		} = this.props;
		// if (status === 'success') this.timeout = setTimeout(toggleDrawer, 450)
		// if (status === 'success' && isOpen) this.timeout = setTimeout(toggleDrawer, 450)
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
	}

	submit = () => {
		const { email } = this.state;
		const { onValidated } = this.props;

		// console.log( this.state )

		if (!(email && isValidEmail(email))) this.setState({ error: 'email' });
		else {
			this.setState({ error: ''})
			onValidated({ EMAIL: email })
		}
		console.log(this.state)
	}

	renderIcon = status => {
		if (status === 'sending') return <MaterialIcon>more_horiz</MaterialIcon>
		if (status === 'success') return <MaterialIcon>check</MaterialIcon>
		if (status === 'error')  return <MaterialIcon>close</MaterialIcon>
		return <MaterialIcon>arrow_forward</MaterialIcon>
	}

	renderText = status => {
		if (status === 'success') return 'Welcome to Hotel Surrender!'
		if (status === 'error')  return 'Email Already Added'
		// return 'Subscribe'
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	render() {
		const { status, inputPlaceholder } = this.props;
		const { error } = this.state;

		return (
			<EmailWrapper>
				<FormWrapper onSubmit={e => { e.preventDefault(); this.submit() }}>
					<Input
						size="small"
						type="email"
						placeholder={inputPlaceholder || 'Email...'}
						onChange={this.handleChange}
						value={this.state.email}
						name="email"
						error={error}
					/>
					<Button htmlType="submit" onClick={() => this.submit()} setTheme="mainColor" size="medium">
						&nbsp;
						{this.renderIcon(status)}
					</Button>
					<span></span>
				</FormWrapper>

				<StatusMessage>
					{this.renderText(status)}
				</StatusMessage>
			</EmailWrapper>
			
		)
	}
}

// eslint-disable-next-line react/prefer-stateless-function
class MailchimpSignup extends Component {
	render() {
		const { inputClassName, placeholder, drawerContext } = this.props;
		return (
			<MailchimpSubscribe
				url={McUrl}
				render={({ subscribe, status, message }) => (
					<CustomForm
						drawerContext={drawerContext}
						status={status}
						message={message}
						onValidated={formData => subscribe(formData)}
						inputClassName={inputClassName}
						inputPlaceholder={placeholder}
					/>
				)}
			/>
		)
	}
}

// export default withDrawerContext(MailchimpSignup)
export default MailchimpSignup





