import React, { Component } from 'react'
import styled from '@emotion/styled'
import * as contentful from 'contentful'
import Splash from 'src/components/SplashPage'

const contentfulClient = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_TOKEN
})

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
`

class PageContent extends Component {
  state = {
    contentfulEntries: null
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(" ╔╗╔╗╦ ╦ \n ║╦╠╦║║║ Designed & built by\n ║║║║║║║ https://gordilsandwillis.com\n ╚╝╚╝╚╩╝ ");
    const ReactPixel =  require('react-facebook-pixel').default;
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);

    contentfulClient.getEntries()
    .then((response) => this.setState({ contentfulEntries: response.items}))
    .catch(console.error)
  }

  render() {
    const { contentfulEntries } = this.state
    const site = contentfulEntries?.filter(item => item.sys.contentType.sys.id.includes('siteSettings') && item.fields.id === 'chetfaker')[0].fields
    return (
      <Wrapper>
        <Splash {...site} />
      </Wrapper>
    )
  }
}

export default PageContent;
