import React, { useState } from 'react';
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import globalStyles from 'src/styles/globalStyles'
import PageContent from 'src/components/PageContent'

import ContentfulState from 'src/state/ContentfulState';
import Modal from 'react-modal';

import './reset.css'

const PageWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

Modal.setAppElement('#root');

const App = () => {

  return (
    <div>
      <Global styles={css`${globalStyles}`} />
      <ContentfulState>
        <PageWrapper>
          <PageContent />
        </PageWrapper>
      </ContentfulState>
    </div>
  )
}

export default App;